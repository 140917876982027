<template>
  <div class="full-screen"
       v-if="fullScreenAvailable"
       @click="goToFullScreen">
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isNil } from 'lodash';
import isMobile from 'ismobilejs';

export default {
  name: 'FullScreen',
  data() {
    return {
      noSleep: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'isFullScreen',
    ]),
    supportedPlatforms() {
      return !isMobile().apple.device && !isMobile().other.firefox;
    },
    fullScreenAvailable() {
      return this.fullScreenEnabled
        && !isNil(this.requestFullScreenAvailable)
        && !this.isFullScreen
        && this.supportedPlatforms;
    },
    fullScreenEnabled() {
      return document.fullscreenEnabled
        || document.webkitFullscreenEnabled
        || document.mozFullScreenEnabled
        || document.msFullscreenEnabled;
    },
    requestFullScreenAvailable() {
      return document.documentElement.requestFullscreen
       || document.documentElement.mozRequestFullScreen
       || document.documentElement.webkitRequestFullscreen
       || document.documentElement.msRequestFullscreen;
    },
  },
  methods: {
    ...mapActions([
      'setFullscreenStatus',
    ]),
    async goToFullScreen() {
      if (this.fullScreenAvailable) {
        const context = document.documentElement;

        const requestFullScreen = context.requestFullscreen
          || context.mozRequestFullScreen
          || context.webkitRequestFullscreen
          || context.msRequestFullscreen;

        await requestFullScreen.bind(document.documentElement)({
          navigationUI: 'hide',
        });
        await this.setFullscreenStatus(true);
      }
    },
    onResize() {
      const fullscreen = (document.fullscreenElement)
        || (document.webkitFullscreenElement)
        || (document.mozFullScreenElement)
        || (document.msFullscreenElement);

      this.setFullscreenStatus(fullscreen);
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
</style>
