<template>
  <div class="modal-back"
       @click.self="closeModal('outside')">
    <div :class="{'modal-back-overlay': showOverlay && isDesktop }"></div>
    <div class="modal"
        :id="htmlId"
        :class="{
          'show-modal': isDesktop && !popupMobileAnimation,
          'popup-show-modal': !isDesktop && popupMobileAnimation,
          'popup-modal-close': modalClose && !isDesktop && popupMobileAnimation,
          'modal-close': modalClose && isDesktop && !popupMobileAnimation,
          'show-modal-mobile': !isDesktop && !popupMobileAnimation,
          'modal-close-mobile': modalClose && !isDesktop && !popupMobileAnimation,
          'desktop': isDesktop,
          }"
         tabindex="0"
         :style="style"
         ref="modal">
      <header v-if="showHeader" class="modal-header">
        <slot name="header">
        </slot>
      </header>
      <section class="modal-body">
        <slot name="body">
        </slot>
      </section>
      <footer v-if="showFooter"
              class="modal-footer">
        <slot name="footer">
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const ESC_KEY = 27;

export default {
  name: 'Modal',
  data() {
    return {
      showOverlay: true,
      modalClose: false,
    };
  },
  props: {
    fullScreen: {
      type: Boolean,
      default: false,
    },
    popupMobileAnimation: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '344px',
    },
    height: {
      type: String,
      default: '400px',
    },
    htmlId: {
      type: String,
    },
  },
  emits: ['closeModal', 'closeModalOutside'],
  methods: {
    closeModal(area) {
      if (area === 'outside') {
        this.modalClose = true;
        setTimeout(() => {
          this.$emit('closeModalOutside');
        }, !this.isDesktop ? 300 : 500);
      } else {
        this.$emit('closeModal');
      }
    },
    closeModalOnEsc(event) {
      if (event.keyCode === ESC_KEY) {
        this.$emit('closeModal');
      }
    },
  },
  computed: {
    ...mapGetters(['isDesktop']),
    style() {
      const styleClasss = {
        width: this.width,
        maxHeight: this.height,
      };
      if (this.fullScreen) {
        styleClasss.width = '100%';
        styleClasss.height = '100%';
      }
      return styleClasss;
    },
  },
  mounted() {
    this.showOverlay = true;
    this.$refs.modal.focus();
    this.$refs.modal.addEventListener('keydown', this.closeModalOnEsc);

    const { modal } = this.$refs;

    const ticketHistoryModal = document.querySelector('.ticket-history-modal .modal');

    if (ticketHistoryModal && this.htmlId === 'cancel-confirm-modal') {
      modal.style.setProperty('--modal-top', `${(ticketHistoryModal.offsetHeight - modal.offsetHeight) / 2}px`);
    }

    // Lock body scroll when modal is open
    document.body.style.overflow = 'hidden';
    setTimeout(() => {
      this.showOverlay = false;
    }, 300);
  },
  // Vue 2 vs Vue3 issue => beforeDestroy hook doesn't exist in vue3 it's changed to beforeUnmount
  beforeUnmount() {
    this.$refs.modal.removeEventListener('keydown', this.closeModalOnEsc);

    document.body.style.overflow = 'auto';
  },
  beforeDestroy() {
    this.$refs.modal.removeEventListener('keydown', this.closeModalOnEsc);

    document.body.style.overflow = 'auto';
  },
};
</script>

<style scoped lang="scss">
.modal-back {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 101;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--overlay-lock);
  animation-name: toggleModal;
  animation-duration: 0.3s;
  transition: all 0.3s cubic-bezier(0, 0, 1, 1);
  animation-fill-mode: backwards;
  --webkit-backface-visibility: hidden;
  --webkit-transform:translate3d(0,0,0);
  --modal-top: 0px;

  .modal-back-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .modal:not(.modal .modal) {
    &.desktop {
      position: absolute;
      top: 150px;
    }
  }

  .modal:not(.modal .modal)#cancel-confirm-modal {
    &.desktop {
      top: calc(150px + var(--modal-top));
    }
  }

  .modal {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    background-color: var(--card);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    outline: none;
    position: relative;
    overflow: visible;

    &.show-modal {
      animation-name: showModal;
      transform: perspective(1px) scale(1) translateZ(0);
      --webkit-transform:translate3d(0,0,0);
      --webkit-backface-visibility: hidden;
      animation-duration: .7s;
      -webkit-font-smoothing: subpixel-antialiased;
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -ms-filter: blur(0px);
      transition: all .7s cubic-bezier(0.42, 0, 0.03, 0.99);
    }
    &.popup-show-modal {
      animation-name: showModal;
      --webkit-transform:translate3d(0,0,0);
      --webkit-backface-visibility: hidden;
      animation-duration: .7s;
      transition: all .7s cubic-bezier(0.42, 0, 0.03, 0.99);
    }
    &.show-modal-mobile {
      animation-name: showModalMobile;
      --webkit-transform:translate3d(0,0,0);
      --webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      will-change: opacity, transform;
      animation-duration: .2s;
      transition: all .2s cubic-bezier(0.0663, 0.4952, 0, 1);
    }
    &.modal-close {
      --webkit-transform:translate3d(0,0,0);
      --webkit-backface-visibility: hidden;
      animation-name: modalClose;
      animation-duration: .5s;
      transition: all .7s cubic-bezier(0, 0, 1, 1);
    }
    &.popup-modal-close {
      --webkit-transform:translate3d(0,0,0);
      --webkit-backface-visibility: hidden;
      animation-name: modalClose;
      animation-duration: .5s;
      transition: all .7s cubic-bezier(0, 0, 1, 1);
    }
    &.modal-close-mobile {
      --webkit-transform:translate3d(0,0,0);
      --webkit-backface-visibility: hidden;
      animation-name: modalCloseMobile;
      animation-duration: .35s;
      transition: all .3s cubic-bezier(1, -0.0048, 1, 1);
    }

    .modal-header {
      padding: 24px 16px 24px 16px;
    }

    .modal-body {
      padding: 8px 16px 0 16px;
      height: 100%;
    }

    .modal-footer {
      display: flex;
      justify-content: flex-end;
      .footer-button {
        cursor: pointer;
        text-align: center;
        line-height: 40px;
        color: var(--secondary);
        height: 40px;
        font-size: 14px;
        width: 80px;
      }
    }
  }
}
@keyframes toggleModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showModal {
  0% {
    opacity: 0;
  }
  30% {
    transform: scale(1);
    transform-origin: center;
    opacity: 0;
  }
  40% {
    transform: scale(1.03);
    transform-origin: center;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    transform-origin: center;
  }
}
@keyframes showModalMobile {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes modalClose {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes modalCloseMobile {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(80px);
  }
}
::v-deep {
  .rebet {
    display: none;
  }
}
@media (max-width: 660px) {
  .modal-back .modal {
    padding: 0;
    max-height: 100% !important;
    height: 100%;
  }

  .modal {
    .modal-footer {
      position: fixed;
      background-color: var(--card);
      border-top: 2px solid var(--tab-border-color); // TODO: define tab-border-color
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
